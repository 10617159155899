import React from 'react';

const AdvancedTechniquesIntroduction = () => {
  return (
    <div style={{
      color: '#ffffff',
      fontFamily: "'Outfit', sans-serif",
      padding: '2rem',
      maxWidth: '800px',
      margin: '0 auto',
      textAlign: 'left',
      overflowY: 'auto',
      height: '100%'
    }}>
      <h1 style={{
        fontSize: '2.5rem',
        fontWeight: '800',
        marginBottom: '1rem',
      }}>
        Técnicas Avanzadas de Prompting: Introducción
      </h1>
      <p style={{ fontSize: '1.25rem', marginBottom: '2rem', fontWeight: '400' }}>
        El prompting avanzado va más allá de simplemente formular preguntas o instrucciones. Implica comprender la estructura de los prompts, los diferentes tipos de modelos y cómo estos factores afectan las respuestas de los LLM.
      </p>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        Estructura de Prompts
      </h2>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        La estructura de un prompt puede variar significativamente dependiendo del modelo y la plataforma:
      </p>
      <ol style={{ fontSize: '1.1rem', marginBottom: '1rem', paddingLeft: '2rem' }}>
        <li>
          <strong>Markdown:</strong>
          <ul style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
            <li>Ventajas: Fácil de leer y escribir, permite formateo claro.</li>
            <li>Desventajas: No todos los modelos lo interpretan de la misma manera.</li>
            <li>Ejemplo: Algunos modelos responden mejor a instrucciones de sistema cuando se presentan en formato markdown.</li>
          </ul>
        </li>
        <li>
          <strong>XML:</strong>
          <ul style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
            <li>Ventajas: Estructura clara y jerárquica, ideal para instrucciones complejas.</li>
            <li>Desventajas: Puede ser más verboso, reduciendo el espacio para el contenido real del prompt.</li>
            <li>Ejemplo: Útil para definir roles o contextos específicos en el prompt.</li>
          </ul>
        </li>
        <li>
          <strong>JSON:</strong>
          <ul style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
            <li>Ventajas: Estructura de datos clara, fácil de procesar para el modelo.</li>
            <li>Desventajas: Puede ser menos intuitivo para usuarios no técnicos.</li>
          </ul>
        </li>
      </ol>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        La elección de la estructura puede afectar la longitud máxima de tokens de entrada, un factor crucial en modelos con límites estrictos.
      </p>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        Tipos de Modelos
      </h2>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        Comprender los diferentes tipos de modelos es esencial para un prompting efectivo:
      </p>
      <ol style={{ fontSize: '1.1rem', marginBottom: '1rem', paddingLeft: '2rem' }}>
        <li>
          <strong>Modelos de código abierto (Open Source):</strong>
          <ul style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
            <li>Características: Pesos del modelo de libre uso, posibilidad de ajuste fino.</li>
            <li>Ejemplos: Modelos Orca de Microsoft, Modelos Mistral, Modelos LLaMA de Meta</li>
            <li>Ventajas: Transparencia, flexibilidad para implementación y modificación.</li>
          </ul>
        </li>
        <li>
          <strong>Modelos de código cerrado (Closed Source):</strong>
          <ul style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
            <li>Características: Código propietario, acceso a través de APIs.</li>
            <li>Ventajas: Generalmente más potentes y refinados.</li>
            <li>Desventajas: Menos flexibilidad, dependencia del proveedor.</li>
          </ul>
        </li>
        <li>
          <strong>Modelos de frontera (Frontier Models):</strong>
          <ul style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
            <li>Características: Representan el estado del arte en capacidades de IA.</li>
            <li>Ejemplos: GPT-4 de OpenAI (código cerrado), LLaMA 3 de Meta (código abierto), Claude 3 de Anthropic (código cerrado), Gemini de Google (código cerrado)</li>
            <li>Nota: Estos modelos ofrecen las capacidades más avanzadas, con diferentes niveles de acceso y apertura.</li>
          </ul>
        </li>
        <li>
          <strong>Modelos descensurados:</strong>
          <ul style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
            <li>Ventajas: Mayor libertad creativa, menos restricciones en las respuestas.</li>
            <li>Desventajas: Requieren consideraciones éticas y de seguridad adicionales.</li>
            <li>Nota: Pueden comportarse mejor en ciertas tareas debido a la falta de filtros, pero su uso requiere precaución.</li>
          </ul>
        </li>
      </ol>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        Consideraciones de Alineamiento
      </h2>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        Cada empresa que entrena un modelo lo ajusta según sus propios criterios:
      </p>
      <ol style={{ fontSize: '1.1rem', marginBottom: '1rem', paddingLeft: '2rem' }}>
        <li><strong>Objetivos del modelo:</strong> Algunos se centran en tareas específicas, otros en versatilidad general.</li>
        <li><strong>Sesgos y ética:</strong> Los ajustes pueden incluir filtros para contenido inapropiado o sesgado.</li>
        <li><strong>Estilo de respuesta:</strong> Algunos modelos se ajustan para ser más formales, otros más conversacionales.</li>
      </ol>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        Entender estas diferencias es crucial para adaptar los prompts y obtener los mejores resultados de cada modelo.
      </p>
    </div>
  );
};

export default AdvancedTechniquesIntroduction;