import React from 'react';

const CommonPromptingErrors = () => {
  return (
    <div style={{
      color: '#ffffff',
      fontFamily: "'Outfit', sans-serif",
      padding: '2rem',
      maxWidth: '800px',
      margin: '0 auto',
      textAlign: 'left',
      overflowY: 'auto',
      height: '100%'
    }}>
      <h1 style={{
        fontSize: '2.5rem',
        fontWeight: '800',
        marginBottom: '1rem',
      }}>
        Errores Comunes a Evitar en Prompting
      </h1>
      <p style={{ fontSize: '1.25rem', marginBottom: '2rem', fontWeight: '400' }}>
        Aunque el prompting puede ser una herramienta poderosa, hay varios errores comunes que pueden limitar su efectividad. Identificar y evitar estos errores es crucial para obtener los mejores resultados de los modelos de lenguaje grande (LLM).
      </p>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        1. Prompts Demasiado Vagos
      </h2>
      <p style={{ fontSize: '1.1rem', marginBottom: '0.5rem' }}>
        <strong>Error:</strong> Usar instrucciones poco claras o demasiado generales.
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '0.5rem' }}>
        <strong>Ejemplo incorrecto:</strong> "Háblame sobre economía."
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '0.5rem' }}>
        <strong>Ejemplo corregido:</strong> "Explica los principios básicos de la oferta y la demanda en economía, incluyendo cómo afectan los precios del mercado."
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        <strong>Por qué es un problema:</strong> Los prompts vagos pueden llevar a respuestas imprecisas o demasiado generales, que no abordan las necesidades específicas del usuario.
      </p>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        2. Sobrecarga de Información
      </h2>
      <p style={{ fontSize: '1.1rem', marginBottom: '0.5rem' }}>
        <strong>Error:</strong> Incluir demasiada información o múltiples preguntas en un solo prompt.
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '0.5rem' }}>
        <strong>Ejemplo incorrecto:</strong> "Explica la Revolución Francesa, sus causas, consecuencias, principales figuras, impacto en Europa y el mundo, y cómo se relaciona con la Revolución Americana. También, compara con la Revolución Rusa."
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '0.5rem' }}>
        <strong>Ejemplo corregido:</strong> "Describe las tres principales causas de la Revolución Francesa y cómo contribuyeron al estallido del conflicto."
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        <strong>Por qué es un problema:</strong> La sobrecarga puede confundir al modelo y resultar en respuestas incompletas o desorganizadas.
      </p>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        3. Falta de Contexto
      </h2>
      <p style={{ fontSize: '1.1rem', marginBottom: '0.5rem' }}>
        <strong>Error:</strong> No proporcionar suficiente contexto para que el modelo entienda completamente la tarea.
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '0.5rem' }}>
        <strong>Ejemplo incorrecto:</strong> "¿Cuál es la mejor opción?"
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '0.5rem' }}>
        <strong>Ejemplo corregido:</strong> "Estoy eligiendo entre tres coches usados para comprar: un Toyota Corolla 2018, un Honda Civic 2019 y un Mazda3 2017. Considerando fiabilidad, consumo de combustible y costo de mantenimiento, ¿cuál sería la mejor opción y por qué?"
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        <strong>Por qué es un problema:</strong> Sin contexto adecuado, el modelo no puede proporcionar una respuesta relevante y precisa.
      </p>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        4. Asumir Conocimiento Previo
      </h2>
      <p style={{ fontSize: '1.1rem', marginBottom: '0.5rem' }}>
        <strong>Error:</strong> Suponer que el modelo tiene información específica de tu situación personal o de eventos muy recientes.
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '0.5rem' }}>
        <strong>Ejemplo incorrecto:</strong> "¿Qué opinas sobre el resultado del partido de anoche?"
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '0.5rem' }}>
        <strong>Ejemplo corregido:</strong> "El equipo de fútbol Barcelona ganó 3-0 contra el Real Madrid anoche en la final de la Liga. ¿Puedes analizar las posibles implicaciones de este resultado para ambos equipos en la próxima temporada?"
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        <strong>Por qué es un problema:</strong> Los LLM no tienen acceso a información en tiempo real o a tu contexto personal específico.
      </p>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        5. Ignorar las Limitaciones del Modelo
      </h2>
      <p style={{ fontSize: '1.1rem', marginBottom: '0.5rem' }}>
        <strong>Error:</strong> Pedir al modelo que realice tareas fuera de sus capacidades, como acceder a internet o realizar cálculos complejos.
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '0.5rem' }}>
        <strong>Ejemplo incorrecto:</strong> "Por favor, busca en Google las últimas estadísticas de desempleo y crea un gráfico detallado."
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '0.5rem' }}>
        <strong>Ejemplo corregido:</strong> "Describe los factores típicos que influyen en las tasas de desempleo y cómo podrían representarse en un gráfico simple."
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        <strong>Por qué es un problema:</strong> Ignorar las limitaciones del modelo puede llevar a respuestas incorrectas o a la imposibilidad de completar la tarea.
      </p>

      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        Evitar estos errores comunes mejorará significativamente la calidad de tus interacciones con los LLM, permitiéndote obtener respuestas más precisas, relevantes y útiles.
      </p>
    </div>
  );
};

export default CommonPromptingErrors;