import React from 'react';

const IntroductionToPrompting = () => {
  return (
    <div style={{
      color: '#ffffff',
      fontFamily: "'Outfit', sans-serif",
      padding: '2rem',
      maxWidth: '800px',
      margin: '0 auto',
      textAlign: 'left',
      overflowY: 'auto',
      height: '100%'
    }}>
      <h1 style={{
        fontSize: '2.5rem',
        fontWeight: '800',
        marginBottom: '1rem',
      }}>
        Introducción al concepto de prompting
      </h1>
      <p style={{ fontSize: '1.25rem', marginBottom: '2rem', fontWeight: '400' }}>
        El prompting es la técnica de comunicación con modelos de lenguaje grande (LLM) mediante instrucciones o preguntas cuidadosamente formuladas. Es la clave para aprovechar al máximo el potencial de estas poderosas herramientas de IA.
      </p>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        Definición de prompting
      </h2>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        El prompting consiste en diseñar y formular entradas de texto (prompts) que guían al LLM para generar las salidas deseadas. Un prompt puede ser una pregunta, una instrucción, un ejemplo o una combinación de estos elementos.
      </p>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        Importancia del prompting en la interacción con LLM
      </h2>
      <ol style={{ fontSize: '1.1rem', marginBottom: '1rem', paddingLeft: '2rem' }}>
        <li><strong>Precisión:</strong> Un prompt bien diseñado ayuda al LLM a entender exactamente lo que se busca, mejorando la precisión de las respuestas.</li>
        <li><strong>Eficiencia:</strong> Prompts efectivos pueden reducir la necesidad de múltiples interacciones, ahorrando tiempo y recursos.</li>
        <li><strong>Versatilidad:</strong> El prompting permite adaptar el LLM a una amplia gama de tareas sin necesidad de reentrenamiento.</li>
        <li><strong>Control:</strong> Mediante prompts específicos, se puede influir en el tono, estilo y formato de las respuestas del LLM.</li>
      </ol>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        Conceptos básicos del prompting efectivo
      </h2>
      <ol style={{ fontSize: '1.1rem', marginBottom: '1rem', paddingLeft: '2rem' }}>
        <li><strong>Claridad:</strong> Usar lenguaje claro y directo en los prompts.</li>
        <li><strong>Contexto:</strong> Proporcionar información relevante para enmarcar la tarea o pregunta.</li>
        <li><strong>Especificidad:</strong> Ser lo más específico posible sobre el resultado deseado.</li>
        <li><strong>Estructura:</strong> Organizar el prompt de manera lógica, a menudo utilizando pasos o puntos numerados.</li>
        <li><strong>Ejemplos:</strong> Incluir ejemplos cuando sea apropiado para ilustrar el tipo de respuesta esperada.</li>
      </ol>

      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        Dominar el arte del prompting es esencial para cualquier persona que busque utilizar LLM de manera efectiva, ya sea para tareas personales, profesionales o de investigación.
      </p>
    </div>
  );
};

export default IntroductionToPrompting;