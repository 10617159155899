import React from 'react';

const IntroductionToLLM = () => {
  return (
    <div style={{
      color: '#ffffff',
      fontFamily: "'Outfit', sans-serif",
      padding: '2rem',
      maxWidth: '800px',
      margin: '0 auto',
      textAlign: 'left',
      overflowY: 'auto',
      height: '100%'
    }}>
      <h1 style={{
        fontSize: '2.5rem',
        fontWeight: '800',
        marginBottom: '1rem',
      }}>
        Introducción a los Modelos de Lenguaje Grande (LLM)
      </h1>
      <p style={{ fontSize: '1.25rem', marginBottom: '2rem', fontWeight: '400' }}>
        Los Modelos de Lenguaje Grande (LLM) son sistemas de inteligencia artificial que están transformando nuestra interacción con las máquinas. Estos modelos, entrenados con vastas cantidades de texto, pueden comprender y generar lenguaje humano con una precisión sorprendente.
      </p>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        Funcionamiento básico
      </h2>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        Los LLM analizan secuencias de palabras y predicen la siguiente palabra más probable en una frase. Este proceso les permite generar texto coherente y contextualmente relevante.
      </p>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        LLM como motor de chatbots avanzados
      </h2>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        Los LLM han revolucionado los chatbots por su:
      </p>
      <ol style={{ fontSize: '1.1rem', marginBottom: '1rem', paddingLeft: '2rem' }}>
        <li><strong>Comprensión contextual:</strong> Captan la intención detrás de las preguntas del usuario.</li>
        <li><strong>Naturalidad:</strong> Generan respuestas que suenan más humanas.</li>
        <li><strong>Versatilidad:</strong> Se adaptan a diversas tareas, desde responder preguntas hasta generar contenido creativo.</li>
      </ol>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        Evolución hacia modelos multimodales
      </h2>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        La última evolución de los LLM es su capacidad multimodal:
      </p>
      <ol style={{ fontSize: '1.1rem', marginBottom: '1rem', paddingLeft: '2rem' }}>
        <li><strong>Integración de formatos:</strong> Procesan y generan no solo texto, sino también imágenes, audio y video.</li>
        <li><strong>Comprensión holística:</strong> Entienden relaciones entre diferentes tipos de datos.</li>
        <li><strong>Adaptabilidad:</strong> Manejan situaciones nuevas o inusuales gracias a su amplio entrenamiento.</li>
        <li><strong>Eficiencia:</strong> Realizan tareas como OCR o análisis de imágenes sin necesidad de herramientas adicionales.</li>
      </ol>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        Esta evolución marca el inicio de una era donde la IA puede interactuar de manera más natural y versátil, difuminando las barreras entre diferentes tipos de datos y abriendo nuevas posibilidades en la interacción humano-máquina.
      </p>
    </div>
  );
};

export default IntroductionToLLM;