import React from 'react';
import Presentation from './pages/introduction-to-prompting';
import Scrambler from './pages/scrambler';
const App = () => {
  const path = window.location.pathname;

  switch(path) {
    case '/pages/introduction-to-prompting':
      return <Presentation />;
    default:
      return (
        <div style={{ height: '100%' }}>
          <Scrambler />
        </div>
    );
  }
};

export default App;