import React from 'react';

const AdvancedTechniques = () => {
  return (
    <div style={{
      color: '#ffffff',
      fontFamily: "'Outfit', sans-serif",
      padding: '2rem',
      maxWidth: '800px',
      margin: '0 auto',
      textAlign: 'left',
      overflowY: 'auto',
      height: '100%'
    }}>
      <h1 style={{
        fontSize: '2.5rem',
        fontWeight: '800',
        marginBottom: '1rem',
      }}>
        Técnicas Avanzadas de Prompting
      </h1>
      <p style={{ fontSize: '1.25rem', marginBottom: '2rem', fontWeight: '400' }}>
        Las técnicas avanzadas de prompting permiten obtener respuestas más precisas y efectivas de los LLM. Aquí presentamos algunas de las más importantes:
      </p>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        1. One-Shot
      </h2>
      <ul style={{ fontSize: '1.1rem', marginBottom: '1rem', paddingLeft: '2rem' }}>
        <li><strong>Definición:</strong> Proporcionar un solo ejemplo para que el modelo comprenda la tarea.</li>
        <li><strong>Uso:</strong> Útil cuando se dispone de espacio limitado en el prompt.</li>
        <li><strong>Ejemplo:</strong> "Cada vez que escribas montos de dinero como por ejemplo $1,000.00 escribelo en formato latino usando punto para los miles y coma para los decimales (ejemplo: $1.000,00)"</li>
      </ul>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        2. Few-Shot
      </h2>
      <ul style={{ fontSize: '1.1rem', marginBottom: '1rem', paddingLeft: '2rem' }}>
        <li><strong>Definición:</strong> Ofrecer múltiples ejemplos para que el modelo capte el patrón.</li>
        <li><strong>Ventaja:</strong> Mayor precisión que One-Shot, especialmente en tareas complejas.</li>
        <li><strong>Ejemplo:</strong> "Cada vez que veas un nombre en el chat quiero que lo formatees a capitalizado cuando respondas, al siguiente estilo. incorrecto: maria paz | correcto: Maria Paz, incorrecto: ines garcia | correcto: Ines Garcia, incorrecto: jose alvarez | correcto: Jose Alvarez"</li>
      </ul>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        3. Chain of Thought (CoT)
      </h2>
      <ul style={{ fontSize: '1.1rem', marginBottom: '1rem', paddingLeft: '2rem' }}>
        <li><strong>Definición:</strong> Guiar al modelo para que muestre su proceso de razonamiento paso a paso.</li>
        <li><strong>Beneficio:</strong> Mejora la precisión en tareas que requieren múltiples pasos de razonamiento.</li>
        <li><strong>Ejemplo:</strong></li>
      </ul>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem', paddingLeft: '2rem' }}>
        "Problema: Si tengo 5 manzanas y doy 2 a mi hermana, ¿cuántas me quedan?<br/>
        Pensemos paso a paso:<br/>
        1. Empiezo con 5 manzanas.<br/>
        2. Le doy 2 manzanas a mi hermana.<br/>
        3. Para calcular cuántas quedan, resto: 5 - 2 = 3 manzanas.<br/>
        Respuesta: Me quedan 3 manzanas.<br/>
        Ahora, resuelve este problema usando el mismo método:<br/>
        Si tengo 10 lápices y pierdo 3, ¿cuántos me quedan?"
      </p>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        4. Role Prompting
      </h2>
      <ul style={{ fontSize: '1.1rem', marginBottom: '1rem', paddingLeft: '2rem' }}>
        <li><strong>Definición:</strong> Asignar un rol específico al modelo para obtener respuestas desde esa perspectiva.</li>
        <li><strong>Uso:</strong> Útil para obtener respuestas especializadas o con un tono particular.</li>
        <li><strong>Ejemplo:</strong> "Actúa como un chef profesional. Describe cómo preparar pasta al pesto."</li>
      </ul>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        5. Prompting Iterativo
      </h2>
      <ul style={{ fontSize: '1.1rem', marginBottom: '1rem', paddingLeft: '2rem' }}>
        <li><strong>Definición:</strong> Refinar gradualmente los prompts basándose en las respuestas anteriores.</li>
        <li><strong>Ventaja:</strong> Permite obtener respuestas más precisas o detalladas con el tiempo.</li>
        <li><strong>Ejemplo:</strong></li>
      </ul>
      <ol style={{ fontSize: '1.1rem', marginBottom: '1rem', paddingLeft: '3rem' }}>
        <li>"Describe brevemente el cambio climático."</li>
        <li>"Basándote en tu respuesta anterior, explica tres efectos principales del cambio climático."</li>
        <li>"De esos tres efectos, profundiza en el que consideres más grave y por qué."</li>
      </ol>

      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        Estas técnicas pueden combinarse y adaptarse según la tarea específica y el modelo utilizado, permitiendo un uso más efectivo y sofisticado de los LLM.
      </p>
    </div>
  );
};

export default AdvancedTechniques;