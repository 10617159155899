import React from 'react';

const IframeRenderer = () => {
  return (
    <iframe
      src="./text-scrambler.html"  // Make sure this path is correct
      title="Text Scrambler"
      width="100%"
      height="100%"
      style={{border: 'none', overflow: 'hidden'}}
    />
  );
};

export default IframeRenderer;