import React from 'react';

const PracticalExamplesEffectivePrompts = () => {
  return (
    <div style={{
      color: '#ffffff',
      fontFamily: "'Outfit', sans-serif",
      padding: '2rem',
      maxWidth: '800px',
      margin: '0 auto',
      textAlign: 'left',
      overflowY: 'auto',
      height: '100%'
    }}>
      <h1 style={{
        fontSize: '2.5rem',
        fontWeight: '800',
        marginBottom: '1rem',
      }}>
        Ejemplos Prácticos de Prompts Efectivos
      </h1>
      <p style={{ fontSize: '1.25rem', marginBottom: '2rem', fontWeight: '400' }}>
        Los ejemplos prácticos son fundamentales para entender cómo aplicar las técnicas avanzadas de prompting en situaciones reales. En esta sección, exploraremos varios escenarios y tareas comunes, demostrando cómo formular prompts efectivos y explicando por qué funcionan.
      </p>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        1. Análisis de Texto (Role Prompting)
      </h2>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        <strong>Tarea:</strong> Analizar el tono y los temas principales de un párrafo.
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        <strong>Prompt:</strong>
      </p>
      <pre style={{
        backgroundColor: '#2b2b2b',
        padding: '1rem',
        borderRadius: '5px',
        overflowX: 'auto',
        fontSize: '0.9rem',
        marginBottom: '1rem'
      }}>
{`Actúa como un crítico literario experto. Analiza el siguiente párrafo, identificando su tono general y dos temas principales. Luego, explica brevemente cómo el autor transmite estos elementos.

Párrafo: "La ciudad se alzaba imponente, sus rascacielos de cristal reflejando el sol poniente. Entre las sombras alargadas, figuras solitarias se apresuraban hacia sus hogares, ajenas al espectáculo que las rodeaba. En el aire flotaba una mezcla de esperanza y melancolía, como si la urbe misma contuviera todos los sueños y desilusiones de sus habitantes."

Formato de respuesta:
1. Tono general:
2. Temas principales:
   a)
   b)
3. Explicación:`}
      </pre>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        <strong>Por qué es efectivo:</strong>
      </p>
      <ul style={{ fontSize: '1.1rem', marginBottom: '1rem', paddingLeft: '2rem' }}>
        <li>Utiliza role prompting (crítico literario experto) para obtener un análisis más profundo.</li>
        <li>Proporciona una estructura clara para la respuesta, facilitando una salida organizada.</li>
        <li>Combina análisis de elementos específicos (tono y temas) con una explicación más amplia.</li>
      </ul>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        2. Evaluación del Tono Emocional (Few-Shot)
      </h2>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        <strong>Tarea:</strong> Evaluar el tono emocional de respuestas en una conversación, calificándolas en una escala del 1 al 10.
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        <strong>Prompt:</strong>
      </p>
      <pre style={{
        backgroundColor: '#2b2b2b',
        padding: '1rem',
        borderRadius: '5px',
        overflowX: 'auto',
        fontSize: '0.9rem',
        marginBottom: '1rem'
      }}>
{`Evalúa el tono emocional de las siguientes respuestas en una escala del 1 al 10, donde:
1 = muy tranquilo y cooperativo
10 = extremadamente molesto y poco cooperativo

Proporciona una breve justificación para cada puntuación.

Ejemplos:
Respuesta: "Claro, estaré encantado de ayudarte con eso. ¿Qué necesitas exactamente?"
Puntuación: 1
Justificación: Tono muy amable y dispuesto a ayudar, claramente cooperativo.

Respuesta: "Bueno, supongo que podría echar un vistazo si realmente es necesario."
Puntuación: 5
Justificación: Tono neutral con un ligero indicio de reluctancia, pero aún dispuesto a ayudar.

Respuesta: "¿Otra vez? Ya te he dicho mil veces que no tengo tiempo para esto."
Puntuación: 9
Justificación: Tono claramente frustrado y poco cooperativo, cerca del extremo de la escala.

Ahora, evalúa las siguientes respuestas:

1. "Lo siento, pero en este momento estoy ocupado. ¿Podríamos hablar de esto más tarde?"

2. "¡Por última vez, no! ¿Es que no entiendes cuando alguien te dice que no?"

3. "Hmm, no estoy seguro. Déjame pensarlo un momento y te digo."`}
      </pre>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        <strong>Por qué es efectivo:</strong>
      </p>
      <ul style={{ fontSize: '1.1rem', marginBottom: '1rem', paddingLeft: '2rem' }}>
        <li>Utiliza la técnica de few-shot, proporcionando ejemplos claros de cómo evaluar y justificar las puntuaciones.</li>
        <li>Establece una escala definida y criterios específicos para la evaluación.</li>
        <li>Permite al modelo practicar con ejemplos variados antes de enfrentarse a las respuestas a evaluar.</li>
        <li>Demuestra cómo los LLM pueden realizar análisis emocional y clasificación basada en texto.</li>
      </ul>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        3. Resolución de Problemas (Chain of Thought)
      </h2>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        <strong>Tarea:</strong> Resolver un problema de lógica paso a paso.
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        <strong>Prompt:</strong>
      </p>
      <pre style={{
        backgroundColor: '#2b2b2b',
        padding: '1rem',
        borderRadius: '5px',
        overflowX: 'auto',
        fontSize: '0.9rem',
        marginBottom: '1rem'
      }}>
{`Eres un tutor de lógica y matemáticas. Resuelve el siguiente problema paso a paso, explicando tu razonamiento en cada etapa:

Problema: En una fiesta hay 20 personas. Cada persona saluda a cada una de las otras con un apretón de manos. ¿Cuántos apretones de manos se dan en total?

Por favor, sigue este formato para tu respuesta:
1. Identifica la información clave del problema.
2. Plantea una estrategia para resolver el problema.
3. Desarrolla la solución paso a paso.
4. Verifica la respuesta.
5. Proporciona la respuesta final.

Asegúrate de explicar cada paso de tu razonamiento de manera clara y concisa.`}
      </pre>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        <strong>Por qué es efectivo:</strong>
      </p>
      <ul style={{ fontSize: '1.1rem', marginBottom: '1rem', paddingLeft: '2rem' }}>
        <li>Utiliza la técnica de Chain of Thought (CoT) para guiar al modelo a través de un proceso de resolución de problemas paso a paso.</li>
        <li>Establece un rol específico (tutor de lógica y matemáticas) para enfocar la respuesta.</li>
        <li>Proporciona una estructura clara para la respuesta, asegurando que se cubran todos los aspectos importantes del proceso de resolución.</li>
        <li>Fomenta una explicación detallada, lo que es útil tanto para verificar el razonamiento del modelo como para propósitos educativos.</li>
      </ul>

      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        Estos ejemplos demuestran cómo las técnicas avanzadas de prompting pueden aplicarse en situaciones prácticas para obtener respuestas más precisas, detalladas y útiles de los modelos de lenguaje grande.
      </p>
    </div>
  );
};

export default PracticalExamplesEffectivePrompts;