import React from 'react';

const BasicPrinciples = () => {
  return (
    <div style={{
      color: '#ffffff',
      fontFamily: "'Outfit', sans-serif",
      padding: '2rem',
      maxWidth: '800px',
      margin: '0 auto',
      textAlign: 'left',
      overflowY: 'auto',
      height: '100%'
    }}>
      <h1 style={{
        fontSize: '2.5rem',
        fontWeight: '800',
        marginBottom: '1rem',
      }}>
        Principios básicos para crear buenos prompts
      </h1>
      <p style={{ fontSize: '1.25rem', marginBottom: '2rem', fontWeight: '400' }}>
        Crear buenos prompts es esencial para obtener respuestas útiles de los LLM. Aquí te presentamos algunos principios básicos con ejemplos sencillos:
      </p>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        1. Sé claro y específico
      </h2>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        Cuanto más claro y específico seas, mejor será la respuesta.
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '0.5rem' }}>
        <strong>Ejemplo malo:</strong> "Háblame de perros."
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        <strong>Ejemplo bueno:</strong> "Describe las características principales de un Labrador Retriever, incluyendo su temperamento y necesidades de ejercicio."
      </p>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        2. Proporciona contexto
      </h2>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        El contexto ayuda al LLM a entender mejor lo que necesitas.
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '0.5rem' }}>
        <strong>Ejemplo malo:</strong> "¿Cuál es la capital?"
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        <strong>Ejemplo bueno:</strong> "Estoy planeando un viaje a Francia. ¿Cuál es la capital y qué atracciones turísticas principales tiene?"
      </p>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        3. Usa instrucciones paso a paso
      </h2>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        Para tareas complejas, divide tu prompt en pasos.
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        <strong>Ejemplo:</strong>
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        "Por favor, ayúdame a escribir un correo electrónico a mi jefe:
      </p>
      <ol style={{ fontSize: '1.1rem', marginBottom: '1rem', paddingLeft: '2rem' }}>
        <li>El correo debe solicitar un día libre para el próximo viernes.</li>
        <li>Explica que necesito asistir a una cita médica importante.</li>
        <li>Ofrece recuperar las horas de trabajo el sábado.</li>
        <li>Mantén un tono profesional y respetuoso."</li>
      </ol>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        4. Especifica el formato deseado
      </h2>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        Si necesitas la información en un formato particular, indícalo.
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        <strong>Ejemplo:</strong> "Enumera 5 consejos para ahorrar dinero. Presenta cada consejo en una frase corta, seguida de una breve explicación de 2-3 líneas."
      </p>

      <h2 style={{ fontSize: '2rem', fontWeight: '700', marginBottom: '1rem' }}>
        5. Utiliza roles o personajes
      </h2>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        Puedes pedirle al LLM que adopte un rol específico para obtener respuestas más enfocadas.
      </p>
      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        <strong>Ejemplo:</strong> "Actúa como un nutricionista y dame consejos para una dieta equilibrada para alguien que quiere perder peso de forma saludable."
      </p>

      <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
        Recuerda, la práctica hace al maestro. Experimenta con diferentes tipos de prompts y observa cómo afectan las respuestas del LLM.
      </p>
    </div>
  );
};

export default BasicPrinciples;