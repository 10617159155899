import React, { useState, useEffect, useMemo } from 'react';
import MobilePresentation from './MobilePresentation';
import IntroductionToLLM from './IntroductionToLLM';
import IntroductionToPrompting from './IntroductionToPrompting';
import BasicPrinciples from './BasicPrinciples';
import AdvancedTechniquesIntroduction from './AdvancedTechniquesIntroduction';
import AdvancedTechniques from './AdvancedTechniques';
import PracticalExamplesEffectivePrompts from './PracticalExamplesEffectivePrompts';
import CommonPromptingErrors from './CommonPromptingErrors';
import ConclusionsRecommendations from './ConclusionsRecommendations';

const chapters = [
  { title: "Introducción a los Modelos de Lenguaje Grande (LLM)", component: IntroductionToLLM },
  { title: "Introducción al concepto de prompting", component: IntroductionToPrompting },
  { title: "Principios básicos para crear buenos prompts", component: BasicPrinciples },
  { title: "Técnicas Avanzadas de Prompting: Introduccion", component: AdvancedTechniquesIntroduction },
  { title: "Técnicas Avanzadas de Prompting", component: AdvancedTechniques },
  { title: "Ejemplos Prácticos de Prompts Efectivos", component: PracticalExamplesEffectivePrompts },
  { title: "Errores Comunes a Evitar en Prompting", component: CommonPromptingErrors },
  { title: "Conclusiones y Recomendaciones Finales", component: ConclusionsRecommendations },
];

const Bubble = ({ size, duration, delay, left }) => {
  const bubbleStyle = {
    position: 'absolute',
    borderRadius: '50%',
    background: 'rgba(255, 255, 255, 0.5)',
    filter: 'blur(20px)',
    animation: `float ${duration}s linear ${delay}s infinite`,
    width: `${size}px`,
    height: `${size}px`,
    left: `${left}%`,
    bottom: `-${size}px`,
  };

  return <div style={bubbleStyle}></div>;
};

const Presentation = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  const changeSlide = (index) => {
    setFadeIn(false);
    setTimeout(() => {
      setCurrentSlide(index);
      setFadeIn(true);
    }, 300);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') changeSlide((currentSlide + 1) % chapters.length);
      if (event.key === 'ArrowLeft') changeSlide((currentSlide - 1 + chapters.length) % chapters.length);
    };

    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('resize', checkMobile);
    checkMobile();

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('resize', checkMobile);
    };
  }, [currentSlide]);

  const bubbles = useMemo(() => {
    return [...Array(15)].map(() => ({
      size: Math.random() * 300 + 200,
      duration: Math.random() * 60 + 40,
      delay: Math.random() * 15,
      left: Math.random() * 100,
    }));
  }, []);

  const backgroundStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: 'linear-gradient(45deg, #40e0d0, #ffb6c1, #ffb6c1, #40e0d0)',
    backgroundSize: '400% 400%',
    animation: 'gradient 15s ease infinite',
    overflow: 'hidden',
  };

  const contentStyle = {
    position: 'relative',
    zIndex: 10,
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: isMobile ? '0.5rem' : '1rem',
    fontFamily: "'Outfit', sans-serif",
    boxSizing: 'border-box',
  };

  const mainAreaStyle = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    flex: 1,
    overflow: 'hidden',
  };

  const sidebarStyle = {
    width: isMobile ? '100%' : '10vw',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(10px)',
    borderRadius: '8px',
    padding: '0.5rem',
    marginRight: isMobile ? '0' : '1rem',
    marginBottom: isMobile ? '1rem' : '0',
    overflowY: 'auto',
    overflowX: isMobile ? 'auto' : 'hidden',
    display: 'flex',
    flexDirection: isMobile ? 'row' : 'column',
    flexWrap: isMobile ? 'nowrap' : 'wrap',
    gap: '0.5rem',
    height: isMobile ? 'auto' : 'calc(100vh - 7rem)',
    scrollbarWidth: 'thin',
    scrollbarColor: 'rgba(255, 255, 255, 0.3) rgba(0, 0, 0, 0)',
  };

  const mainContentStyle = {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(10px)',
    borderRadius: '12px',
    padding: isMobile ? '1rem' : '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'center',
    transition: 'opacity 0.3s ease-in-out',
    opacity: fadeIn ? 1 : 0,
    overflowY: 'auto',
    height: isMobile ? 'calc(100vh - 200px)' : 'calc(100vh - 10rem)',
  };

  const chapterButtonStyle = {
    width: isMobile ? 'auto' : '100%',
    padding: '0.5rem',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'left',
    fontSize: isMobile ? '0.8rem' : '0.9rem',
    transition: 'background-color 0.3s ease',
    color: '#ffffff',
    fontFamily: "'Outfit', sans-serif",
    fontWeight: '500',
    whiteSpace: isMobile ? 'nowrap' : 'normal',
  };

  const footerStyle = {
    width: '100%',
    textAlign: 'center',
    padding: '0.1rem',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    backdropFilter: 'blur(10px)',
    color: '#ffffff',
    fontFamily: "'Outfit', sans-serif",
    fontSize: '0.9rem',
    borderRadius: '8px',
    marginTop: '1rem',
    boxSizing: 'border-box',
  };

  const CurrentChapterComponent = chapters[currentSlide].component;

  return (
    <div style={{height: '100vh', width: '100vw', overflow: 'hidden', marginTop: isMobile ? '0' : '1rem'}}>
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

          @keyframes gradient {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
          }
          @keyframes float {
            from { transform: translateY(0); }
            to { transform: translateY(-200vh); }
          }
          
          /* Custom scrollbar styles */
          ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
          }
          
          ::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0);
          }
          
          ::-webkit-scrollbar-thumb {
            background-color: rgba(255, 255, 255, 0.3);
            border-radius: 3px;
          }
          
          ::-webkit-scrollbar-thumb:hover {
            background-color: rgba(255, 255, 255, 0.5);
          }
          
          /* Firefox scrollbar styles */
          * {
            scrollbar-width: thin;
            scrollbar-color: rgba(255, 255, 255, 0.3) rgba(0, 0, 0, 0);
          }

          /* Footer link styles */
          footer a {
            color: #ffffff;
            text-decoration: none;
          }

          footer a:hover {
            text-decoration: underline;
          }

          body, html {
            margin: 0;
            padding: 0;
            overflow: hidden;
          }
        `}
      </style>
      <div style={backgroundStyle}>
        {bubbles.map((bubble, i) => (
          <Bubble
            key={i}
            size={bubble.size}
            duration={bubble.duration}
            delay={bubble.delay}
            left={bubble.left}
          />
        ))}
      </div>
      <div style={contentStyle}>
        {isMobile ? (
          <MobilePresentation chapters={chapters} />
        ) : (
          <div style={mainAreaStyle}>
            <div style={sidebarStyle}>
              {chapters.map((chapter, index) => (
                <button
                  key={index}
                  onClick={() => changeSlide(index)}
                  style={{
                    ...chapterButtonStyle,
                    backgroundColor: currentSlide === index ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 255, 255, 0.1)',
                  }}
                >
                  <div style={{fontWeight: '700'}}>Capítulo {index + 1}</div>
                  <div style={{fontSize: '0.8rem', fontWeight: '400'}}>{chapter.title}</div>
                </button>
              ))}
            </div>
            <div style={mainContentStyle}>
              <CurrentChapterComponent />
            </div>
          </div>
        )}
        <footer style={footerStyle}>
          Prompted with ❤️ by <a href="https://twitter.com/goldenfox27">🦊</a>
        </footer>
      </div>
    </div>
  );
};

export default Presentation;