import React, { useState, useRef, useEffect } from 'react';

const MobilePresentation = ({ chapters }) => {
  const [activeChapter, setActiveChapter] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const chapterRefs = useRef(chapters.map(() => React.createRef()));

  useEffect(() => {
    const handleScroll = () => {
      let newActiveChapter = 0;

      chapterRefs.current.forEach((ref, index) => {
        if (ref.current) {
          const element = ref.current;
          const { top, bottom } = element.getBoundingClientRect();
          if (top <= 100 && bottom > 100) {
            newActiveChapter = index;
          }
        }
      });

      setActiveChapter(newActiveChapter);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToChapter = (index) => {
    chapterRefs.current[index].current.scrollIntoView({ behavior: 'smooth' });
    setMenuOpen(false);
  };

  const containerStyle = {
    minHeight: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: '#ffffff',
    fontFamily: "'Outfit', sans-serif",
    padding: '1rem',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    position: 'relative',
  };

  const chapterStyle = {
    marginBottom: '2rem',
    paddingTop: '1rem',
  };

  const titleStyle = {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    textAlign: 'left',
  };

  const hamburgerStyle = {
    position: 'fixed',
    top: '1rem',
    left: menuOpen ? 'calc(80% - 50px)' : '1rem', // Adjust position when menu is open
    maxLeft: menuOpen ? '250px' : 'auto', // Limit maximum left position
    zIndex: 1002, // Ensure it's above the menu
    cursor: 'pointer',
    fontSize: '1.5rem',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: menuOpen ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.1)',
    color: '#fff',
    borderRadius: '5px',
    transition: 'all 0.3s ease-in-out',
  };

  const menuStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '80%',
    maxWidth: '300px',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    padding: '4rem 1rem 1rem 1rem',
    transform: menuOpen ? 'translateX(0)' : 'translateX(-100%)',
    transition: 'transform 0.3s ease-in-out',
    zIndex: 1001,
    overflowY: 'auto',
    boxShadow: menuOpen ? '0 0 15px rgba(0, 0, 0, 0.5)' : 'none',
  };

  const menuItemStyle = {
    padding: '0.5rem',
    cursor: 'pointer',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  };

  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
    opacity: menuOpen ? 1 : 0,
    pointerEvents: menuOpen ? 'auto' : 'none',
    transition: 'opacity 0.3s ease-in-out',
  };

  return (
    <>
      <div style={overlayStyle} onClick={() => setMenuOpen(false)} />
      <div style={containerStyle}>
        <div style={hamburgerStyle} onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? '×' : '☰'}
        </div>
        <div style={menuStyle}>
          {chapters.map((chapter, index) => (
            <div
              key={index}
              style={{
                ...menuItemStyle,
                backgroundColor: activeChapter === index ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
              }}
              onClick={() => scrollToChapter(index)}
            >
              Capítulo {index + 1}: {chapter.title}
            </div>
          ))}
        </div>
        {chapters.map((chapter, index) => (
          <div key={index} ref={chapterRefs.current[index]} style={chapterStyle}>
            <h2 style={titleStyle}>
              Capítulo {index + 1}: {chapter.title}
            </h2>
            <chapter.component />
          </div>
        ))}
      </div>
    </>
  );
};

export default MobilePresentation;